<template>
  <b-container class="page-container">
    <bread-crumbs
      :bread-crumbs="[{ title: 'Mijn account', path: '/account/contact' }]"
      :currentpage="`Nieuw subaccount toevoegen`"
    />
    <b-form @submit.prevent="createNewSub">
      <h1>Nieuw subaccount toevoegen</h1>

      <b-row>
        <b-col sm="12" lg="8">
          <b-card>
            <h4>GEGEVENS VAN DE NIEUWE PERSOON</h4>

            <b-row>
              <b-col cols="6" class="pb-3">
                <label>
                  Voornaam
                  <span class="text-danger">*</span>
                </label>
                <b-form-input v-model="form.firstname" required></b-form-input>
              </b-col>
              <b-col cols="6" class="pb-3">
                <label>
                  Achternaam
                  <span class="text-danger">*</span>
                </label>
                <b-form-input v-model="form.lastname" required></b-form-input>
              </b-col>

              <b-col sm="12" md="6">
                <b-form-group id="input-group-tel" label-for="input-tel">
                  <label>
                    Gsm
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    id="input-tel"
                    v-model="form.CONTACT_MobilePhone"
                    type="text"
                    name="mobiletel"
                    required
                    oninput="javascript: this.value = this.value.replace(/[A-Za-z]/gi, '');"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <label>
                  Telefoonnummer
                  <span class="text-danger"></span>
                </label>
                <b-form-input
                  v-model="form.tel"
                  oninput="javascript: this.value = this.value.replace(/[A-Za-z]/gi, '');"
                ></b-form-input>
              </b-col>
              <b-col xs="12" md="12" class="pb-3">
                <label>
                  E-mailadres
                  <span class="text-danger">*</span>
                </label>
                <b-form-input v-model="form.email" required></b-form-input>
              </b-col>
              <b-col cols="12">
                <hr />
                <b-form-text id="password-help-block">
                  <p>
                    Een nieuwe wachtwoord moet aan de volgende eisen voldoen:
                  </p>
                  <ul>
                    <li>- Minimaal 8 karakters zijn.</li>
                    <li>- Met minimaal één hoofdletter. A t/m Z.</li>
                    <li>- Met minimaal één kleine letter. a t/m z.</li>
                    <li>- Met minimaal één cijfer. 0 t/m 9.</li>
                    <li>- Met minimaal één speciale teken, keuze uit !@$%&</li>
                  </ul>
                </b-form-text>
              </b-col>
              <b-col
                v-if="!passwordValidation && form.password.length > 0"
                cols="12"
                class="pb-3"
              >
                <span class="text-danger"
                  >Uw wachtwoord voldoet niet aan de eisen</span
                >
              </b-col>
              <b-col cols="6" class="pb-3">
                <label>
                  Wachtwoord
                  <span class="text-danger">*</span>
                </label>
                <b-input-group>
                  <b-form-input
                    v-model="form.password"
                    required
                    :state="passwordValidation"
                    :type="showPassword ? 'text' : 'password'"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      title="Wachtwoord tonen"
                      variant="primary"
                      @click="showPasswordToggle()"
                    >
                      <font-awesome-icon v-if="!showPassword" fas icon="eye" />
                      <font-awesome-icon
                        v-if="showPassword"
                        fas
                        icon="eye-slash"
                      />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col cols="6">
                <label>
                  Herhaal wachtwoord
                  <span class="text-danger">*</span>
                </label>
                <b-input-group>
                  <b-form-input
                    v-model="form.confirmPassword"
                    :type="showPassword ? 'text' : 'password'"
                    :state="passwordConfirmState"
                    required
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      title="Wachtwoord tonen"
                      variant="primary"
                      @click="showPasswordToggle()"
                    >
                      <font-awesome-icon v-if="!showPassword" fas icon="eye" />
                      <font-awesome-icon
                        v-if="showPassword"
                        fas
                        icon="eye-slash"
                      />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>
          </b-card>
          <div class="mt-5">
            <b-alert v-if="error" show variant="danger">{{
              errorMessage
            }}</b-alert>
          </div>
          <b-card class="mt-5 mb-5">
            <b-row>
              <b-col>
                <h2 class="pt-2">Bevestigen?</h2>
              </b-col>
              <b-col class="text-right">
                <b-button variant="primary" type="submit">
                  Subaccount aanmaken
                  <font-awesome-icon
                    v-if="processing"
                    class="fa-spin"
                    far
                    :icon="['fad', 'spinner']"
                  />
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import { newSub } from '@/services/AuthorizationService'
import {
  BCol,
  BRow,
  BContainer,
  BFormInput,
  BButton,
  BCard,
  BInputGroup,
  BFormText,
  BInputGroupAppend,
  BFormGroup,
  BForm
} from 'bootstrap-vue'
export default {
  components: {
    BCol,
    BForm,
    BRow,
    BContainer,
    BFormInput,
    BButton,
    BCard,
    BInputGroup,
    BFormText,
    BInputGroupAppend,
    BFormGroup
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
        confirmPassword: '',
        tel: '',
        firstname: '',
        lastname: '',
        CONTACT_MobilePhone: ''
      },
      registering: false,
      formFilled: false,
      processing: false,
      error: null,
      showPassword: false
    }
  },
  computed: {
    passwordConfirmState() {
      if (this.form.password != '' && this.form.password != null) {
        if (this.form.password == this.form.confirmPassword) {
          return true
        }
        return false
      }
      return null
    },
    passwordValidation() {
      let password = this.form.password
      if (password != '') {
        if (password.length < 8) {
          return false
        }

        if (!password.match(/[!@#$%&]/)) return false
        if (!password.match(/[0-9]/)) return false
        if (!password.match(/[a-z]/)) return false
        if (!password.match(/[A-Z]/)) return false
        if (!/^([0-9a-zA-Z!@#$%&]*)$/.exec(password)) return false

        return true
      }

      return null
    }
  },
  methods: {
    showPasswordToggle: function() {
      if (this.showPassword) {
        this.showPassword = false
      } else {
        this.showPassword = true
      }
    },
    createNewSub: async function() {
      this.processing = true

      if (!this.passwordValidation) {
        this.processing = false
        this.incorrectPassword = true
        return
      }
      if (this.form.password === this.form.confirmPassword) {
        const result = await newSub({
          email: this.form.email,
          password: this.form.password,
          confirmPassword: this.form.confirmPassword,
          tel: this.form.tel,
          firstname: this.form.firstname,
          lastname: this.form.lastname,
          CONTACT_MobilePhone: this.form.CONTACT_MobilePhone
        })

        if (result.data.Message === 'MaxUserCountHasBeenReached') {
          this.errorMessage =
            'Het maximale aantal subgebruikers is bereikt, kan sub gebruiker niet aanmaken.'
          this.error = true
          this.processing = false
          return
        }

        if (
          result.data.Message === 'The entered e-mail address is unauthorized.'
        ) {
          this.errorMessage =
            'Er bestaad reeds een sub gebruiker met het opgegeven emailadres, het is niet mogelijk om het zelfde emailadres voor meerdere subgebruikers te gebruiken.'
          this.error = true
          this.processing = false
          return
        }
        this.$toasted.show(`Gebruiker succesvol aangemaakt`)

        this.$router.push({
          path: '/account/contact',

        })
      } else {
        this.processing = false
      }
    }
  }
}
</script>

<style></style>
